import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName, TDMWeightKindMap } from '../../models/country';
import { compareDates, formatYearMonth, formatYearMonthDay, formatYearMonthDayHour } from '../../helpers/date';
import { ErrorReport, UserConsentMap } from '../../models/errorReport';
import { withKeys } from '../../helpers/array';
import { AWSRegionTypeMap } from '../../models/awsRegion';
import { compareValues } from '../../helpers/compare';
import { Truncate } from '../../models/truncate';
import { UserIPMap } from '../../models/userIP';
import { getProviderDisplayName } from '../../helpers/provider';

interface errorReportColumn {
    key: string;
    date: string;
    kind_app: string;
    region: string;
    rq_bundle: string;
    country_code: string;
    v_provider: string;
    traffic_distribute_mode: string;
    user_category: string;
    user_consent: number;
    zone: string;
    user_ip: number;
    tmax: number;
    purposes_consent: number;
    purposes_li_consent: number;
    vendors_consent: number[];
    vendors_li_consent: number[];
    total_errors: number;
    unexpected_status: number;
    client_err_ctx_deadline: number;
    client_err_ctx_cancelled: number;
    client_err_dial_tcp_timeout: number;
    client_err_other: number;
    failed_read_body: number;
    reading_response_body: number;
    url_blacklisted: number;
    empty_response: number;
    no_content: number;
    ad_blocked: number;
    duplicate_in_multislots: number;
    duplicate_of_creative: number;
    blocked_app: number;
    no_consent: number;
    empty_oaid_gaid: number;
    high_bidfloor: number;
    unsupported_template: number;
    unsupported_app: number;
    no_images_or_featured_media: number;
    unsupported_country: number;
    langs_not_equal: number;
    empty_market_url: number;
    no_gallery_link: number;
    no_ip: number;
    oaid_request: number;
    no_settings: number;
    empty_imp_object: number;
    empty_device_object: number;
    undefined_country: number;
    incorrect_assets: number;
    inactive_country: number;
    unsupported_split_key: number;
    request_validation_failed: number;
    unsupported_winteraction_type: number;
    template_unsupported_by_bundle: number;
    unknown_imp_id: number;
    deduplication_err: number;
    bad_image_url: number;
    unknown_errors: number;
}

interface ErrorReportTableProps {
    loading: boolean;
    error: null | string;
    errorReportStats: ErrorReport[];
    countriesList: Country[];
    groupByList: string[];
}

const ErrorReportTable: React.FC<ErrorReportTableProps> = props => {
    const { loading, errorReportStats, groupByList } = props;
    const defaultPageSize = 30;

    const getScrollX = () => 3300 + 100 * groupByList.length;

    const formattedDate = (date: any) => {
        if (groupByList.includes(Truncate.Hourly)) {
            return formatYearMonthDayHour(date);
        }
        if (groupByList.includes(Truncate.Monthly)) {
            return formatYearMonth(date);
        }
        return formatYearMonthDay(date);
    };

    const getColumns = (): ColumnsType<errorReportColumn> => {
        const groupColumns: ColumnsType<errorReportColumn> = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                align: 'right',
                width: 80,
                fixed: 'left',
                sorter: (a, b) => compareDates(a.date || '', b.date || ''),
                defaultSortOrder: 'ascend',
                render: date => <span style={{ whiteSpace: 'nowrap' }}>{formattedDate(date)}</span>,
            },
            {
                title: 'Region',
                dataIndex: 'region',
                key: 'region',
                align: 'left',
                fixed: 'left',
                width: 80,
                sorter: (a, b) => a.region.localeCompare(b.region),
                render: (region: string): string => AWSRegionTypeMap[region],
            },
            {
                title: 'Traffic distribute mode',
                dataIndex: 'traffic_distribute_mode',
                key: 'traffic_distribute_mode',
                align: 'right',
                fixed: 'left',
                width: 80,
                sorter: (a, b) => (
                    a.traffic_distribute_mode.toString().localeCompare(b.traffic_distribute_mode.toString())
                ),
                render: (tdm: string): string => TDMWeightKindMap[tdm],
            },
            {
                title: 'API',
                dataIndex: 'kind_app',
                key: 'kind_app',
                align: 'left',
                fixed: 'left',
                width: 80,
                sorter: (a, b) => compareValues(a.kind_app, b.kind_app),
            },
            {
                title: 'Country Name',
                dataIndex: 'country_code',
                key: 'country_code',
                align: 'left',
                fixed: 'left',
                width: 100,
                sorter: (a, b) => compareValues(a.country_code, b.country_code),
                render: (country: string) => getCountryFullName(country),
            },
            {
                title: 'Zone',
                dataIndex: 'zone',
                key: 'zone',
                align: 'left',
                width: 70,
                fixed: 'left',
                sorter: (a, b) => a.zone.localeCompare(b.zone),
            },
            {
                title: 'Bundle',
                dataIndex: 'rq_bundle',
                key: 'rq_bundle',
                align: 'left',
                width: 150,
                fixed: 'left',
                sorter: (a, b) => compareValues(a.rq_bundle, b.rq_bundle),
            },
            {
                title: 'Provider',
                dataIndex: 'v_provider',
                key: 'v_provider',
                align: 'right',
                fixed: 'left',
                width: 90,
                sorter: (a, b) => compareValues(a.v_provider, b.v_provider),
                render: provider => getProviderDisplayName(provider),
            },
            {
                title: 'User category',
                dataIndex: 'user_category',
                key: 'user_category',
                align: 'right',
                fixed: 'left',
                width: 70,
                sorter: (a, b) => a.user_category.toString().localeCompare(b.user_category.toString()),
            },
            {
                title: 'User consent',
                dataIndex: 'user_consent',
                key: 'user_consent',
                align: 'right',
                fixed: 'left',
                width: 70,
                sorter: (a, b) => a.user_consent.toString().localeCompare(b.user_consent.toString()),
                render: (userConsent: string): string => UserConsentMap[userConsent],
            },
            {
                title: 'User IP',
                dataIndex: 'user_ip',
                key: 'user_ip',
                align: 'right',
                fixed: 'left',
                width: 100,
                sorter: (a, b) => a.user_ip.toString().localeCompare(b.user_ip.toString()),
                render: (userIP: string): string => UserIPMap[userIP],
            },
            {
                title: 'Tmax',
                dataIndex: 'tmax',
                key: 'tmax',
                align: 'right',
                fixed: 'left',
                width: 100,
                sorter: (a, b) => a.tmax - b.tmax,
            },
            {
                title: 'Purposes',
                dataIndex: 'purposes_consent',
                key: 'purposes_consent',
                align: 'right',
                fixed: 'left',
                width: 100,
                sorter: (a, b) => a.purposes_consent - b.purposes_consent,
            },
            {
                title: 'Purposes LI',
                dataIndex: 'purposes_li_consent',
                key: 'purposes_li_consent',
                align: 'right',
                fixed: 'left',
                width: 100,
                sorter: (a, b) => a.purposes_li_consent - b.purposes_li_consent,
            },
            {
                title: 'Vendors',
                dataIndex: 'vendors_consent',
                key: 'vendors_consent',
                align: 'right',
                fixed: 'left',
                width: 100,
            },
            {
                title: 'Vendors LI',
                dataIndex: 'vendors_li_consent',
                key: 'vendors_li_consent',
                align: 'right',
                fixed: 'left',
                width: 100,
            },
        ];
        const filtered = groupByList.map(item => {
            if (Object.values(Truncate).includes((item as Truncate))) item = 'date';
            return groupColumns.find(col => col.key!.toString() === item)!;
        });
        return [
            ...filtered,
            {
                title: 'Total errors',
                dataIndex: 'total_errors',
                key: 'totalErrors',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.total_errors - b.total_errors,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unexpected status',
                dataIndex: 'unexpected_status',
                key: 'unexpectedStatus',
                align: 'right',
                width: 150,
                showSorterTooltip: { title: 'http4xx and http5xx status codes' },
                sorter: (a, b) => a.unexpected_status - b.unexpected_status,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Context deadline',
                dataIndex: 'client_err_ctx_deadline',
                key: 'clientErrCtxDeadline',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.client_err_ctx_deadline - b.client_err_ctx_deadline,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Context cancelled',
                dataIndex: 'client_err_ctx_cancelled',
                key: 'clientErrCtxCancelled',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.client_err_ctx_cancelled - b.client_err_ctx_cancelled,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Dial TCP timeout',
                dataIndex: 'client_err_dial_tcp_timeout',
                key: 'clientErrDialTCPTimeout',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.client_err_dial_tcp_timeout - b.client_err_dial_tcp_timeout,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Other client errors',
                dataIndex: 'client_err_other',
                key: 'clientErrOther',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.client_err_other - b.client_err_other,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Failed to read body',
                dataIndex: 'failed_read_body',
                key: 'failedReadBody',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.failed_read_body - b.failed_read_body,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Reading response body',
                dataIndex: 'reading_response_body',
                key: 'readingResponseBody',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.reading_response_body - b.reading_response_body,
                render: err => err.toLocaleString(),
            },
            {
                title: 'URL blacklisted',
                dataIndex: 'url_blacklisted',
                key: 'urlBlacklisted',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.url_blacklisted - b.url_blacklisted,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Empty response',
                dataIndex: 'empty_response',
                key: 'emptyResponse',
                align: 'right',
                width: 120,
                showSorterTooltip: { title: 'http200 status code, but with no ads provided' },
                sorter: (a, b) => a.empty_response - b.empty_response,
                render: err => err.toLocaleString(),
            },
            {
                title: 'No content',
                dataIndex: 'no_content',
                key: 'noContent',
                align: 'right',
                width: 120,
                showSorterTooltip: { title: 'http204 status code' },
                sorter: (a, b) => a.no_content - b.no_content,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Ad blocked',
                dataIndex: 'ad_blocked',
                key: 'adBlocked',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.ad_blocked - b.ad_blocked,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Duplicate in multislots',
                dataIndex: 'duplicate_in_multislots',
                key: 'duplicateInMultislots',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.duplicate_in_multislots - b.duplicate_in_multislots,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Duplicate of creative',
                dataIndex: 'duplicate_of_creative',
                key: 'duplicateOfCreative',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.duplicate_of_creative - b.duplicate_of_creative,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Blocked app',
                dataIndex: 'blocked_app',
                key: 'blockedApp',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.blocked_app - b.blocked_app,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Empty OAID GAID',
                dataIndex: 'empty_oaid_gaid',
                key: 'emptyOAIDGAID',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.empty_oaid_gaid - b.empty_oaid_gaid,
                render: err => err.toLocaleString(),
            },
            {
                title: 'No consent',
                dataIndex: 'no_consent',
                key: 'noConsent',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.no_consent - b.no_consent,
                render: err => err.toLocaleString(),
            },
            {
                title: 'High bidfloor',
                dataIndex: 'high_bidfloor',
                key: 'highBidfloor',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.high_bidfloor - b.high_bidfloor,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unsupported template',
                dataIndex: 'unsupported_template',
                key: 'unsupportedTemplate',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.unsupported_template - b.unsupported_template,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unsupported / Blocked app',
                dataIndex: 'unsupported_app',
                key: 'unsupportedApp',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.unsupported_app - b.unsupported_app,
                render: err => err.toLocaleString(),
            },
            {
                title: 'No images or featured meadia',
                dataIndex: 'no_images_or_featured_media',
                key: 'noImagesOrFeaturedMedia',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.no_images_or_featured_media - b.no_images_or_featured_media,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unsupported country',
                dataIndex: 'unsupported_country',
                key: 'unsupportedCountry',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.unsupported_country - b.unsupported_country,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Langs not equal',
                dataIndex: 'langs_not_equal',
                key: 'langsNotEqual',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.langs_not_equal - b.langs_not_equal,
                render: err => err.toLocaleString(),
            },
            {
                title: 'No gallery link',
                dataIndex: 'no_gallery_link',
                key: 'noGalleryLink',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.no_gallery_link - b.no_gallery_link,
                render: err => err.toLocaleString(),
            },
            {
                title: 'No IP',
                dataIndex: 'no_ip',
                key: 'noIP',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.no_ip - b.no_ip,
                render: err => err.toLocaleString(),
            },
            {
                title: 'OAID request',
                dataIndex: 'oaid_request',
                key: 'oaidRequest',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.oaid_request - b.oaid_request,
                render: err => err.toLocaleString(),
            },
            {
                title: 'No settings',
                dataIndex: 'no_settings',
                key: 'noSettings',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.no_settings - b.no_settings,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Empty market URL',
                dataIndex: 'empty_market_url',
                key: 'emptyMarketURL',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.empty_market_url - b.empty_market_url,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Empty imp object',
                dataIndex: 'empty_imp_object',
                key: 'emptyImpObject',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.empty_imp_object - b.empty_imp_object,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Empty device object',
                dataIndex: 'empty_device_object',
                key: 'emptyDeviceObject',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.empty_device_object - b.empty_device_object,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Undefined country',
                dataIndex: 'undefined_country',
                key: 'undefinedCountry',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.undefined_country - b.undefined_country,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Incorrect assets',
                dataIndex: 'incorrect_assets',
                key: 'incorrectAssets',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.incorrect_assets - b.incorrect_assets,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Inactive country',
                dataIndex: 'inactive_country',
                key: 'inactiveCountry',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.inactive_country - b.inactive_country,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unsupported split key',
                dataIndex: 'unsupported_split_key',
                key: 'unsupportedSplitKey',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.unsupported_split_key - b.unsupported_split_key,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Request validation failed',
                dataIndex: 'unsupported_split_key',
                key: 'requestValidationFailed',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.request_validation_failed - b.request_validation_failed,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unsupported winteraction type',
                dataIndex: 'unsupported_winteraction_type',
                key: 'unsupportedWinteractionType',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.unsupported_winteraction_type - b.unsupported_winteraction_type,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Template is unsupported by bundle',
                dataIndex: 'template_unsupported_by_bundle',
                key: 'templateUnsupportedByBundle',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.template_unsupported_by_bundle - b.template_unsupported_by_bundle,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unknown Imp ID',
                dataIndex: 'unknown_imp_id',
                key: 'unknownImpId',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.unknown_imp_id - b.unknown_imp_id,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Deduplication error',
                dataIndex: 'deduplication_err',
                key: 'deduplicationErr',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.deduplication_err - b.deduplication_err,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Bad image URL',
                dataIndex: 'bad_image_url',
                key: 'badImageURL',
                align: 'right',
                width: 150,
                sorter: (a, b) => a.bad_image_url - b.bad_image_url,
                render: err => err.toLocaleString(),
            },
            {
                title: 'Unknown errors',
                dataIndex: 'unknown_errors',
                key: 'unknownErrors',
                align: 'right',
                width: 120,
                sorter: (a, b) => a.unknown_errors - b.unknown_errors,
                render: err => err.toLocaleString(),
            },
        ];
    };

    return (
        <Table
            id="errorreporttable"
            className="error-report__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(errorReportStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
            scroll={{ x: getScrollX(), y: '42vh' }}
            tableLayout="auto"
            size="small"
        />
    );
};

export default ErrorReportTable;
